import { observer } from 'mobx-react';

import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';

import yandex from 'assets/images/yandexmap.svg';
import avito from 'assets/images/messageBlack.svg';

import './MarketsSelector.scss';

const MarketsSelector = observer(({ store }) => {
  return (
    <div className='market-selector'>
      <p className='market-selector__title'>Куда пишем отзыв?</p>
      <div className='market-selector__buttons'>
        <ButtonWithIcon
          title='Яндекс Карты'
          className={`market-selector__button-yandex ${store.marketplace === 'yandex' ? 'active' : ''}`}
          icon={yandex}
          onClick={() => store.setMarketplace('yandex')}
          ariaLabel='Выбрать площадку для публикации - Яндекс.Карты'
        />
        <ButtonWithIcon
          title='Авито'
          className={`market-selector__button-avito ${store.marketplace === 'avito' ? 'active' : ''}`}
          icon={avito}
          onClick={() => store.setMarketplace('avito')}
          ariaLabel='Выбрать площадку для публикации - Авито'
        />
      </div>
    </div>
  );
});

export default MarketsSelector;
