import { observer } from 'mobx-react';
import { useCallback } from 'react';

import ServiceCard from './ServiceCard/ServiceCard';
import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';

import settingsIcon from 'assets/images/settings.svg';

import './ServicesContent.scss';

const ServicesContent = observer(({ store, isMobile }) => {
  const cardData = [
    { iconClass: 'icon-eye', reviewCount: 1, pf: 4, deadline: 5, actionCount: 25, price: 446 },
    { iconClass: 'icon-message', reviewCount: 2, pf: 8, deadline: 7, actionCount: 50, price: 892 },
    { iconClass: 'icon-message', reviewCount: 3, pf: 12, deadline: 9, actionCount: 75, price: 1338 },
    { iconClass: 'icon-message', reviewCount: 4, pf: 16, deadline: 11, actionCount: 100, price: 1784 },
    { iconClass: 'icon-message', reviewCount: 5, pf: 20, deadline: 13, actionCount: 125, price: 2230 },
    { iconClass: 'icon-message', reviewCount: 6, pf: 24, deadline: 15, actionCount: 150, price: 2676 },
  ];

  const handleOrder = useCallback((cardParams) => {
      const encodedReviewCount = btoa(encodeURIComponent(cardParams.reviewCount.toString()));
      const url = `${store.publishUrl}?number-reviews=${encodedReviewCount}`;
      store.handleRedirect(url);
    },
    [store]
  );

  const handleCustomButton = () => {
    const url = process.env.REACT_APP_BASE_URL;
    store.handleRedirect(url);
  };

  return (
    <div className='services-content'>
      <div className='services-content__container'>
        {cardData.map((card, index) => (
          <ServiceCard
            key={index}
            {...card}
            price={card.price.toLocaleString()}
            onOrder={() => handleOrder(card)}
            iconButton={settingsIcon}
          />
        ))}
        <div className='services-content__special-card'>
          <div className='services-content__special-card-text'>
            <p className='services-content__special-card-title'>Готовые стратегии размещения</p>
            <p className='services-content__special-card-description'>Наши специалисты рассчитали формулы для того, чтобы отзывы на ваших аккаунтах выглядели естественно!</p>
          </div>
          <div className='services-content__buttons'>
            <ButtonWithIcon
              title={`${isMobile ? 'Узнать подробнее' : 'Настроить под себя'}`}
              className='services-content__button-setting'
              icon={settingsIcon}
              onClick={() => {
                handleCustomButton();
                window.scroll(0, 0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ServicesContent;
