import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import LoadingContent from 'components/content/LoadingContent/LoadingContent';
import GenderSelector from 'components/GenderSelector/GenderSelector';
import OptionSelector from 'components/OptionSelector/OptionSelector';
import StarRating from 'components/StarRating/StarRating';
import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';
import Skeleton from 'components/UI/Skeleton/Skeleton';
import ErrorContent from 'components/content/ErrorContent/ErrorContent';
import InformativeContent from 'components/content/InformativeContent/InformativeContent';
import ResultHeader from 'components/content/ResultHeader/ResultHeader';
import MarketsSelector from 'components/MarketsSelector/MarketsSelector';
import SeoContent from 'components/content/SeoContent/SeoContent';
import ServicesContent from 'components/content/ServicesContent/ServicesContent';

import { appStore } from 'AppStore';

import { useMatchMedia } from 'utils/hooks/use-match-media';

import create from 'assets/images/create-button.svg';
import repeat from 'assets/images/repeat-button.svg';
import sendDis from 'assets/images/sendDis-button.svg';
import send from 'assets/images/send.svg';

import './Main.scss';

const REVIEW_PRICE_AVITO = 250;
const REVIEW_PRICE_YANDEX_MAPS = 350;

const Main = observer(() => {
  const { isMobile } = useMatchMedia();
  const [isLoading, setIsLoading] = useState(false);
  const [showServicesContent, setShowServicesContent] = useState(true);

  const productInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const reviewResultRef = useRef(null);
  const emotionsSelectorRef = useRef(null);
  const characteristicsSelectorRef = useRef(null);

  const formattedText = appStore.reviewResult
    .split('\n\n')
    .map((paragraph, index) => (
      <p key={index} style={{ marginBottom: '12px' }}>
        {paragraph}
      </p>
    ));

  const scrollToRef = (ref, block = 'start') => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block,
      });
    }
  };

  const handleGenerateReview = () => {
    if (!appStore.validateFields()) {
      if (appStore.emotionsError) scrollToRef(emotionsSelectorRef);
      if (appStore.characteristicsError) scrollToRef(characteristicsSelectorRef);
      if (appStore.productError) scrollToRef(productInputRef);
      if (appStore.commentError) scrollToRef(commentInputRef);
      return;
    }

    setIsLoading(true);
    scrollToRef(reviewResultRef);
    appStore.generateReview().finally(() => {
      setIsLoading(false);
    });
  };

  const handleRandomFill = () => {
    appStore.randomFill();
    if (appStore.productError) scrollToRef(productInputRef, 'center');
  };

  const renderResultContent = () => {
    if (isLoading) return <> <Skeleton /> <LoadingContent /> </>
    if (appStore.reviewResultError) return <> <Skeleton /> <ErrorContent repeatRequest={handleGenerateReview} errorMessage={appStore.reviewResultError} /> </>
    if (appStore.reviewResult) return <div className='main_result-generation-content-review'>{formattedText}</div>

    return <InformativeContent randomFill={handleRandomFill} />;
  };

  const publishReview = () => {
    const reviewParam = btoa(encodeURIComponent(appStore.reviewResult));
    const url = `${appStore.publishUrl}?review-text=${reviewParam}`;
    appStore.handleRedirect(url);
  }

  useEffect(() => {
    if (window.location.hash === '#settings') {
      setShowServicesContent(false);
    }
  }, []);

  return (
    <main className='main'>

      <Helmet>
        <title>Создание отзывов. Генерация через нейросеть GPT4 - бесплатно</title>
        <meta name="description" content="Создавайте отзывы бесплатно с помощью нейросети GPT-4. Наш сервис предлагает простой способ генерации отзывов для вашего бизнеса или продукта. Попробуйте сейчас!" />
        <meta name="robots" content="all" />
      </Helmet>

      <div className='main__inner'>
        <MarketsSelector store={appStore} />
        {isMobile &&
          <div className='main__settings-star'>
            <p className='main__settings-star-title'>Начните с выбора оценки</p>
            <StarRating value={appStore.stars} onChange={(stars) => appStore.setStars(stars)} />
          </div>
        }
        {showServicesContent && appStore.marketplace === 'yandex' && <ServicesContent store={appStore} isMobile={isMobile} />}      
        <div className='main__container'>
          <div className='main__settings'>
            <div className='main__settings-rating-container'>
              {!isMobile && <StarRating value={appStore.stars} onChange={(stars) => appStore.setStars(stars)} />}
              <div className='main__settings-rating-container-others'>
                <OptionSelector
                  title='Выберите эмоции для отзыва'
                  options={appStore.emotionOptions}
                  selected={appStore.emotions}
                  onToggle={(emotion) => appStore.toggleEmotion(emotion)}
                  maxSelect={3}
                  error={appStore.emotionsError}
                  selectorRef={emotionsSelectorRef}
                />
                <OptionSelector
                  title='Характеристики для отзыва'
                  options={appStore.characteristicOptions}
                  selected={appStore.characteristics}
                  onToggle={(characteristic) => appStore.toggleCharacteristic(characteristic)}
                  maxSelect={3}
                  error={appStore.characteristicsError}
                  selectorRef={characteristicsSelectorRef}
                />
              </div>
            </div>

            <div className='main__settings-product'>
              <div ref={productInputRef}>
                <p className='main__settings-label'>
                  О каком продукте или услуге идет речь?
                </p>
                {appStore.productError && <p className='main__settings-product-error'>{appStore.productError}</p>}
                <input
                  className={appStore.productError ? 'error' : ''}
                  type='text'
                  maxLength={150}
                  placeholder='Компания или услуга, на которую пишем отзыв'
                  value={appStore.product}
                  onChange={(e) => appStore.setProduct(e.target.value)}
                />
              </div>
              <div ref={commentInputRef}>
                <p className='main__settings-label'>Что важно упомянуть? </p>
                {appStore.commentError && <p className='main__settings-product-error'>{appStore.commentError}</p>}
                <input
                  className={appStore.commentError ? 'error' : ''}
                  type='text'
                  minLength={15}
                  maxLength={450}
                  placeholder='Детали, имена, факты, которые нужно отразить'
                  value={appStore.comment}
                  onChange={(e) => appStore.setComment(e.target.value)}
                />
              </div>

              <div className='main__settings-product-write-reviews'>
                <p>Кто пишет отзыв?</p>
                <GenderSelector store={appStore} />
              </div>
            </div>

            <ButtonWithIcon
              title='Создать отзыв'
              className='main__settings-create-button'
              icon={create}
              onClick={handleGenerateReview}
              ariaLabel='Создать отзыв'
            />
          </div>

          <div className='main_result' ref={reviewResultRef}>
            <div className='main_result__container'>
              <ResultHeader store={appStore} isMobile={isMobile} />

              <div className='main_result-generation'>
                <div className='main_result-generation-content'>
                  {renderResultContent()}
                </div>

                <div className='main_result-generation-buttons'>
                  <p className='main_result-generation-buttons-description'>
                    Иногда нужно несколько попыток, чтобы вам понравился отзыв.
                    <br />
                    Нажмите "Ещё вариант", чтобы получить другой вариант.
                  </p>
                  <div className='main_result-generation-buttons__container'>
                    <div className='main_result-generation-function-buttons'>
                      <ButtonWithIcon
                        title={`Ещё\u00a0вариант`}
                        className='main_result-generation-repeat-button'
                        icon={repeat}
                        onClick={handleGenerateReview}
                        ariaLabel='Повторить генерацию отзыва'
                        disabled={!appStore.reviewResult}
                      />
                    </div>
                    {!isMobile && (
                      <ButtonWithIcon
                        title={`Опубликовать от ${appStore.marketplace === 'yandex' ? REVIEW_PRICE_YANDEX_MAPS : REVIEW_PRICE_AVITO}\u00a0₽`}
                        className='main_result-generation-post-button'
                        icon={!appStore.reviewResult ? sendDis : send}
                        onClick={publishReview}
                        ariaLabel={`Опубликовать сгенерированный отзыв от ${appStore.marketplace === 'yandex' ? REVIEW_PRICE_YANDEX_MAPS : REVIEW_PRICE_AVITO}\u00a0₽`}
                        disabled={!appStore.reviewResult}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isMobile && (
            <ButtonWithIcon
              title={`Опубликовать от ${appStore.marketplace === 'yandex' ? REVIEW_PRICE_YANDEX_MAPS : REVIEW_PRICE_AVITO}\u00a0₽`}
              className='main_result-generation-post-button'
              icon={!appStore.reviewResult ? sendDis : send}
              onClick={publishReview}
              ariaLabel={`Опубликовать сгенерированный отзыв от ${appStore.marketplace === 'yandex' ? REVIEW_PRICE_YANDEX_MAPS : REVIEW_PRICE_AVITO}\u00a0₽`}
              disabled={!appStore.reviewResult}
            />
          )}
        </div>
      </div>
      <SeoContent store={appStore} />
    </main>
  );
});

export default Main;
